import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADEPT_DATA } from 'Consts/routes';

import { getFullName } from 'Utils/user';
import { getFormattedDate } from 'Utils/date';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import TextBox from 'Components/layout/panel/TextBox';

export default class AdeptUserHomeworkEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            changeCompleted: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
    };

    state = {
        formState: {
            ...this.props.predefinedState, //eslint-disable-line react/destructuring-assignment
        },
    };

    componentDidMount = () => {
        const { data } = this.props;

        if (data) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
        trainer: getFullName(data.trainer).label,
    })
    
    render() {
        const { actions, history } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="adept-user-homework-editor"
                styles={require('./styles')}
            >
                <div className="user-homework-details">
                    <div className="detail-item description">
                        <TextBox 
                            title="Opis"
                            subtitle={formState.description}
                            styleVersion={2}
                        />
                    </div>
                    <div className="detail-item trainer">
                        <TextBox
                            title="Trener"
                            subtitle={formState.trainer}
                            styleVersion={2}
                        />
                    </div>
                    <div className="detail-item deadline">
                        <TextBox
                            title="Termin wykonania"
                            subtitle={getFormattedDate(formState.deadlineAt)}
                            styleVersion={2}
                        />
                    </div>
                    <div className="route-select">
                        <div className="routes">
                            <a 
                                className="route"
                                onClick={() => actions.changeCompleted({ id: formState.id }).then(response => {
                                    history.push(
                                        withVariables(
                                            ADEPT_DATA.path,
                                            {},
                                            { tab: 'userHomeworks' }
                                        )
                                    );
                                })}
                            >
                                <h3 className="route-headline">
                                    {formState.isCompleted ? (
                                        'Oznacz jako niewykonaną'
                                    ) : (
                                        'Oznacz jako wykonaną'
                                    )}
                                </h3>
                                <p className="route-hint">
                                    Potwierdź wykonanie pracy domowej.
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </StyledComponent>
        );
    }
}