import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .user-homework-details {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 1em;

            .detail-item {
                display: flex;
                margin-bottom: .75em;
                color: white;
            }

            .description {
                width: 100%;

                .admin-panel-text-box {
                    width: 100% !important;
                }
            }

            .trainer {
                width: 49.5%;

                .admin-panel-text-box {
                    width: 100% !important;
                }
            }
    
            .deadline {
                width: 49.5%;

                .admin-panel-text-box {
                    width: 100% !important;
                }
            }
        }

        .route-select {
            width: 100%;

            .routes {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 2em;

                .route {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    border: 1px solid #666666;
                    padding: 2em;
                    min-height: 15em;
                    cursor: pointer;
                    text-align: center;
                    margin: 0 1em;
                    width: 100%;

                    .route-headline {
                        color: ${variables.dpc_fontDark};
                        font-weight: 600;
                        font-size: 1.4em;
                    }

                    .route-hint {
                        color: ${variables.dpc_fontDark};
                        font-size: 1em;
                        font-weight: 300;
                        margin-top: 1em;

                        &.route-hint-alert {
                            color: #DD3544;
                            font-weight: 600;
                        }
                    }

                    &:hover {
                        background: ${variables.dpc_backgroundLightRed};
                        border-color: #DD3544;
                    }

                    &.disabled {
                        cursor: default;
                        background: #403f3f;

                        &:hover {
                            background: #403f3f;
                            border-color: #666666;
                        }
                    }
                }
            }
        }
    `;
