import { connect } from 'react-redux';
import Component from './component';

import {
    changeCompleted,
} from 'Redux/modules/adept/userHomeworks/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            changeCompleted: dispatch(changeCompleted),
        },
    }),
)(Component);